import React, { PureComponent, useState, useCallback, useEffect } from "react";

import Link from "../LinkWithTransitionLock"
import InViewObserver from "../InViewObserver"

class Page extends PureComponent {

  constructor(props) {
    super(props);
  }
  
  componentDidMount() {

    InViewObserver()

  }

  componentWillUnmount() {}

  render() {
    return (

      <div className="content_wrap ">

        <div className="page_header">
          <h2 className="sub_title">郭恆成</h2>
          <h1 className="main_title">社宅公共藝術未來式</h1>
        </div>


        <article className="page_content blog_single_post_content">

          <section className="row_area_s">
            <div className="column_3">
              <figure className="figure">
                <img src={require("../../images/blog/post_3_1.jpg")} alt="郭恆成"/>
              </figure>
            </div>
          </section>
          <section className="row_area_s">
            <div className="column_5">
              <div className="context">
                <p>延續前場提出的公共藝術介入機制與時機問題，提出了想法及經驗分享。建築師理想中，藝術與建築的交集，應是碰撞、擴張，現況卻非如此，有時甚至彼此侷限、沒有交集。</p>
                <p>面對社會住宅的集居模式，建築師曾考慮過設計實驗性公宅，重新定義住家。但受社會住宅房型的設計規範限制，住家空間較難有發揮，因此轉向對公共空間的規劃進行一些實驗。目前舊宗、河濱社宅的開放了一些設計，如社區共享空間、聯繫步道，或是連結社宅居住空間與公共設施之間的介面。他將之稱為村口或生活劇本，認為是公共藝術很好介入的空間。關於介入方式，目前他能做的是在公共空間中規畫建議位置，適度留白設計，如街道傢具、鋪面或公共設備，讓藝術家來發揮。未來建築使用執照取得前後，藝術家可以到現場參考這些設計者建議的空間。</p>
                <p>建築師分享14年前設計環河北路跨堤陸橋的案例，才發現藝術與建築整合不容易。最後建築師女兒的創意圖畫與橋體的圖案表現，大家不禁莞爾讚賞。郭建築師藉此經驗提問：現行機制中，公共藝術與建築何時能產生交集？</p>
                <p>建築跟公部門之間的整套歷程，包含競圖─評選─基本設計─細部設計─施工─驗收使用。建築師在競圖階段發揮設計、評選之後，在基本設計階段開始修改，選擇可堅持、可退讓的元素。到了細部設計的時候，都是為了鋪陳後面的施工，建築師處理的事情太多，配合施工期程也非常趕，在這個階段配合整合公共藝術的條件就更少。</p>
                <p>所以，在實際狀況裡，一般公共藝術的介入時機，都是在建築快完工、或使用執照取得之後，建築環境已完整，藝術家發揮的空間相對受限。</p>
                <p>目前的評選機制，是否有空間，讓藝術家從基本設計階段參與，或是透過自主審查表、參建單位等機制的調整，讓建築師的設計和公共藝術能彼此回應。</p>
                <p>期許未來建築與公共藝術的交集能激起精彩的火花，而非流於形式的妥協。不僅是一位建築師的渴望，同時是社會的期盼。</p>
              </div>
            </div>
          </section>

        </article>

        <div className="page_footer">
          <Link to="/blog/post_3_2" className="next_page">
            <div className="next_title">社宅公共藝術進行式</div>
            <div className="next_owner">戴嘉惠</div>
          </Link>
        </div>

      </div>

    );
  }
}


export default Page
